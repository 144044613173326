<template>
  <div v-if="videos.length">
    <div v-for="(video, videoIndex) in videos" :key="`key-${videoIndex}`">
      <ExerciseVideoListItem :video="video" :index="videoIndex"></ExerciseVideoListItem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseVideoList',
  components: {
    ExerciseVideoListItem: () => import('@/components/ExerciseVideoListItem'),
  },
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
